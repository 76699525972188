import React, { useState } from "react";
import "./App.css";
import { ClipboardIcon, TrashIcon, DocumentDuplicateIcon } from "@heroicons/react/24/solid";


// Place these functions above your App component
function countWords(text) {
    const words = text.trim().split(/\s+/);
    return words[0] === "" ? 0 : words.length;
}

function countCharacters(text) {
    return text.length;
}

function countSentences(text) {
    const sentences = text.split(/[.!?]+/).filter(sentence => sentence.trim().length > 0);
    return sentences.length;
}

function countParagraphs(text) {
    const paragraphs = text.split(/\n+/).filter(paragraph => paragraph.trim().length > 0);
    return paragraphs.length;
}

function calculateReadingTime(wordCount) {
    const timeInMinutes = Math.ceil(wordCount / 200);
    return `${timeInMinutes} min`;
}

function calculateWordDensity(text) {
    const words = text.trim().toLowerCase().split(/\s+/).filter(word => word);
    const wordMap = {};
    words.forEach(word => {
        wordMap[word] = (wordMap[word] || 0) + 1;
    });
    const totalWords = words.length;
    return Object.entries(wordMap)
        .map(([word, count]) => ({
            word,
            count,
            percentage: ((count / totalWords) * 100).toFixed(2),
        }))
        .sort((a, b) => b.count - a.count)
        .slice(0);
}

function App() {
  const [text, setText] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [charCount, setCharCount] = useState(0);
  const [readingTime, setReadingTime] = useState("0 min");
  const [sentenceCount, setSentenceCount] = useState(0);
  const [paragraphCount, setParagraphCount] = useState(0);
  const [wordDensity, setWordDensity] = useState([]);

    const handleTextChange = (e) => {
        const inputText = e.target.value;
        setText(inputText);

        const wordCount = countWords(inputText);
        const charCount = countCharacters(inputText);
        const sentenceCount = countSentences(inputText);
        const paragraphCount = countParagraphs(inputText);
        const readingTime = calculateReadingTime(wordCount);
        const wordDensity = calculateWordDensity(inputText);

        // Update states
        setWordCount(wordCount);
        setCharCount(charCount);
        setSentenceCount(sentenceCount);
        setParagraphCount(paragraphCount);
        setReadingTime(readingTime);
        setWordDensity(wordDensity);
    };

    // Paste Text
    const handlePasteText = async () => {
        const text = await navigator.clipboard.readText();
        setText(text);
        handleTextChange({ target: { value: text } }); // Update states with pasted text
    };

// Clear Text
    const handleClearText = () => {
        setText("");
        handleTextChange({ target: { value: "" } }); // Clear all counts
    };

// Copy Text
    const handleCopyText = () => {
        navigator.clipboard.writeText(text);
        alert("Text copied to clipboard!");
    };

// Save Text
    const handleSaveText = () => {
        const blob = new Blob([text], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "text-output.txt";
        a.click();
        URL.revokeObjectURL(url);
    };


    return (
      <div className=" flex items-center justify-center min-h-screen">
        <div className=" overflow-hidden w-full max-w-6xl mx-4 md:mx-auto">
          <h1 className="text-5xl font-bold text-center py-4 text-gray-600">Word Count Application</h1>
          <div className="flex flex-col md:flex-row">
              <TextInputSection
                  text={text}
                  handleTextChange={handleTextChange}
                  handlePasteText={handlePasteText}
                  handleClearText={handleClearText}
                  handleCopyText={handleCopyText}
                  handleSaveText={handleSaveText}
              />
            <DetailsPanel
                wordCount={wordCount}
                charCount={charCount}
                readingTime={readingTime}
                sentenceCount={sentenceCount}
                paragraphCount={paragraphCount}
                wordDensity={wordDensity}
            />
          </div>
        </div>
      </div>
  );
}


function TextInputSection({ text, handleTextChange, handlePasteText, handleClearText, handleCopyText, handleSaveText }) {
    return (
        <div className="w-full md:w-2/3 p-6 border-gray-200">
            <h2 className="text-2xl font-extrabold text-gray-700 mb-3">Text Input</h2>
            <textarea
                value={text}
                onChange={handleTextChange}
                className="w-full h-116 p-4 border border-gray-300 rounded resize-none focus:outline-none focus:ring-2 focus:ring-gray-200 placeholder-gray-400"
                placeholder="Type or paste your text here..."
            />

            <div className="flex space-x-3 mt-4">
                <button onClick={handlePasteText} className="border rounded-3xl hover:border-gray-400 flex items-center space-x-2 hover:text-gray-800 text-gray-600 font-medium py-2 px-4">
                    <ClipboardIcon className="h-5 w-5" />
                    <span>Paste</span>
                </button>
                <button onClick={handleClearText} className="border rounded-3xl hover:border-gray-400 flex items-center space-x-2 hover:text-gray-800 text-gray-600 font-medium py-2 px-4">
                    <TrashIcon className="h-5 w-5" />
                    <span>Clear</span>
                </button>
                <button onClick={handleCopyText} className="border rounded-3xl hover:border-gray-400 flex items-center space-x-2 hover:text-gray-800 text-gray-600 font-medium py-2 px-4">
                    <DocumentDuplicateIcon className="h-5 w-5" />
                    <span>Copy</span>
                </button>
                <button onClick={handleSaveText} className="border rounded-3xl hover:border-gray-400 flex items-center space-x-2 hover:text-gray-800 text-gray-600 font-medium py-1 px-4">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="h-5 w-5 text-gray-800"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 13.5l3 3m0 0l3-3m-3 3v-6m1.06-4.19L10.94 3.19a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
                        />
                    </svg>
                    <span>Save</span>
                </button>

            </div>
        </div>
    );
}

function DetailsPanel({
                          wordCount,
                          charCount,
                          readingTime,
                          sentenceCount,
                          paragraphCount,
                          wordDensity,
                      }) {
    return (
        <div className="w-full md:w-1/3 py-5">
            <h3 className="text-2xl font-extrabold text-gray-700 mb-4">Statistics</h3>

            <div className="grid grid-cols-2">
                <div className="p-3 rounded-tl border border-gray-200">
                    <div className="p-3 bg-gray-50 space-y-2">
                        <p className="text-sm text-muted-foreground">WORDS</p>
                        <p className="text-3xl font-bold">{wordCount}</p>
                    </div>
                </div>
                <div className="p-3 rounded-tr border border-gray-200">
                    <div className="p-3 bg-gray-50 space-y-2">
                        <p className="text-sm text-muted-foreground">CHARACTERS</p>
                        <p className="text-3xl font-bold">{charCount}</p>
                    </div>
                </div>
                <div className="p-3 border border-gray-200">
                    <div className="p-3 bg-gray-50 space-y-2">
                        <p className="text-sm text-muted-foreground">SENTENCES</p>
                        <p className="text-3xl font-bold">{sentenceCount}</p>
                    </div>
                </div>
                <div className="p-3 border border-gray-200">
                    <div className="p-3 bg-gray-50 space-y-2">
                        <p className="text-sm text-muted-foreground">PARAGRAPHS</p>
                        <p className="text-3xl font-bold">{paragraphCount}</p>
                    </div>
                </div>
                <div className="p-3 rounded-bl border border-gray-200">
                    <div className="p-3 bg-gray-50 space-y-2 ">
                        <div className="flex items-center gap-2">
                            <p className="text-sm text-muted-foreground">READING TIME</p>
                        </div>
                        <p className="text-3xl font-bold">{readingTime}</p>
                    </div>
                </div>
            </div>
            <div className="mt-6 border-t-2">
                <h3 className="text-2xl font-extrabold text-gray-700 mt-4 mb-4">Word Density:</h3>

                <div className="overflow-y-auto max-h-64">
                    <table className="min-w-full table-auto">
                        <thead>
                        <tr className="bg-gray-100 border-b">
                            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">Word</th>
                            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">Count</th>
                            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">Percentage</th>
                        </tr>
                        </thead>
                        <tbody>
                        {wordDensity.length === 0 ? (
                            <tr>
                                <td colSpan="3" className="px-4 py-2 text-sm text-gray-600 text-center">
                                    No word density data available.
                                </td>
                            </tr>
                        ) : (
                            wordDensity.map((word, index) => (
                                <tr key={index} className="border-b">
                                    <td className="px-4 py-2 text-sm text-gray-600">{word.word}</td>
                                    <td className="px-4 py-2 text-sm text-gray-600">{word.count}</td>
                                    <td className="px-4 py-2 text-sm text-gray-600">{word.percentage}%</td>
                                </tr>
                            ))
                        )}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    );
}

export default App;
